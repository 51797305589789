@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.dashboard-periodwisebar-bg {
  width: 1090px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px;
  padding-right: 50px;
  background: #ffffff;
  border: 0.25px solid #e2e9ff;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  box-sizing: border-box;
  margin-right: 10px;
}

.course-analysis-title {
  font-family: "Lato";
  font-weight: 900;
  font-size: 14px;
  color: #606981;
  letter-spacing: 0.5px;
  font-style: normal;
}

.filters {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 25%;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 700;
  color: #456bf1;
  font-style: normal;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 92% !important;
  outline: none;
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.filter-select:hover {
  background-color: rgb(231, 239, 250);
}

.individual-gaps {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gaps-heading {
  color: #000;
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 104.762% */
}

.filter-po-container {
  background-color: #d4e5ff;
  border-radius: 7px;
  margin-left: 20px;
  margin-top: 50px;
  width: 100px;
  height: 40px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-select-po {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 700;
  color: #182b68;
  font-style: normal;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 90%;
  outline: none;
  width: 100%;
  margin: 0;
  padding-left: 15px;
  cursor: pointer;
  /* text-align: center; */
}
