.bg-containers {
  border: 1px solid #e2e9ff;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
}

.art-matrix-title {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: left;
  color: #606981;
  margin-bottom: 15px;
}

.table-th {
  font-family: "Merriweather Sans";
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  text-align: center;
}

.table-td {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  width: 180px;
  text-align: center;
}

.update-changes-btn {
  width: 180px;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  background-color: #3d65f4;
  color: #ffffff;
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.weightage-input {
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  width: 40%;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  border: 1.5px solid #f1f3f5;
  background-color: #f9fbff;
  color: #606981;
  margin-right: 8px;
  text-align: center;
  outline-color: #3d65f4;
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
}

.label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #040c58;
  margin-top: 10px;
}

.filter-txt {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 10px;
  outline: none;
  width: 50%;
  height: 40px;
}

.filter-txt::placeholder {
  opacity: 0.5;
}
