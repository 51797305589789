@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.table {
  border-width: 10px;
}

.title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #040c58;
}

.subtitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #040c58;
}

.text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.no-course-structure-text {
  color: #9fb2f6;
  text-align: center;
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 157.143% */
  margin-bottom: 15px;
}

.no-course-structure-btn {
  width: 250px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #1c60ff;
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: 'Lato';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
}
