@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.overlay-title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #606981;
}

.overlay-heading {
  /* font-size: 19px; */
  font-family: "Lato";
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.25px;
  margin-right: 8%;
  cursor: pointer;
}

.label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8f949f;
  margin-bottom: 8px;
}

.select-sec-label {
  font-family: Merriweather Sans;
  font-size: 16px;
  font-weight: 500;
  color: #606981;
}

.filter-select-sec {
  width: 90px;
  font-size: 16px;
  font-family: "Merriweather Sans";
  font-weight: 500;
  color: #ffffff;
  font-style: normal;
  border: none;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrowWhite.svg") no-repeat;
  background-position: calc(100% - 10px);
  background-color: #456bf1;
  outline: none;
  padding-left: 10px;
  margin-left: 20px;
}

.filter-select-sec > option {
  background-color: #ffffff;
  color: #456bf1;
}

.buttons {
  color: #456bf1;
  font-size: 14px;
  font-family: "Merriweather Sans";
  line-height: 24.462px;
}

.input-file {
  position: absolute;
  z-index: -1;
  top: 10px;
  left: 8px;
  font-size: 17px;
  color: #b8b8b8;
}

.button-wrap {
  position: relative;
  display: flex;
  align-items: center;
}

.button {
  display: inline-block;
  padding: 12px 18px;
  cursor: pointer;
  border-radius: 5px;
  background: #456bf1;
  color: #fff;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  text-transform: uppercase;
}

.sample-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 150px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #e2e9ff;
  background: #f5f8fc;
  backdrop-filter: blur(40.5px);
  color: #456bf1;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  margin-right: 18px;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 5px;
}

.file {
  color: #8f949f;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  margin-left: 20px;
}

.file-uploaded {
  color: green;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  margin-left: 20px;
}

.sample-excel-modal {
  background-color: #ffffff;
  position: relative;
  width: 1000px;
  min-height: 500px;
  max-height: 550px;
  padding: 10px;
  overflow-y: auto;
  border-radius: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.temp-name {
  color: #606981;
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
  margin-top: 10px;
  margin-bottom: 20px;
}

.li-item {
  border-radius: 5px;
  border: 1.5px solid #f1f3f5;
  background: #fff;
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  padding-left: 40px;
  margin-left: 10px;
}

.errors-text {
  color: #000;
  font-family: "Lato";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.419px; /* 142.796% */
  letter-spacing: 0.335px;
}

.errors-list {
  color: #606981;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  align-self: flex-start;
  margin-top: 40px;
  list-style-type: none;
}

.errors-list > li {
  margin-bottom: 10px;
}

.errors-list > li > span {
  color: red;
  margin-right: 4px;
}

.filter-select {
  width: 120px;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  outline: none;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.filter-select:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-select:focus {
  color: #606981;
  opacity: 1;
}

.exam-type {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #456bf1;
}

.excel-course-info {
  font-family: Merriweather Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #040c58;
  margin-right: 30px;
}

.analysis-btn {
  background-color: #456bf1;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  border-radius: 40px;
  margin-right: 18px;
}

.excel-header {
  background-color: #fff;
  position: relative;
  top: -5%;
  padding-top: 30px;
}
