.excel-width {
  width: 100% !important;
  height: 110%;
  max-height: 110%;
  display: flex;
  z-index: 0;
}

.excel-container {
  height: 60vh;
  max-height: 60vh;
  position: relative;
  padding-bottom: 4%;
}

.custom-handsontable td,
.custom-handsontable th {
  border-color: #567fff !important;
  text-align: center !important;
}

.highlighted-cell {
  background-color: rgba(227, 80, 80, 0.3) !important;
  border-color: red !important;
}

.read-only-cell {
  text-align: center;
  background-color: #456bf1 !important;
  color: #ffffff !important;
}

.read-only-cell-custom {
  text-align: center;
  background-color: #456bf1 !important;
  color: #ffffff !important;
  padding-top: 10px !important;
}

.custom-handsontable-placeholder {
  color: #b8c6f0;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
}

.excel-btns {
  position: relative;
  bottom: 0;
  right: 0;
}

.button {
  display: inline-block;
  padding: 10px 18px;
  border-radius: 5px;
  background: #456bf1;
  color: #fff;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.back-button {
  display: inline-block;
  padding: 10px 18px;
  border-radius: 5px;
  background: #f5f8fc;
  color: #456bf1;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid #e2e9ff;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errors-card {
  border: 3px solid #e2e9ff;
  border-radius: 3px;
  width: 35%;
  margin-left: 10px;
  padding-bottom: 10px;
  height: 100%;
}

.err-count-title {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #e35050;
  background-color: #e2e9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  position: relative;
}

.err-container {
  overflow-y: auto;
  height: 90%;
}

.errors-list {
  color: #606981;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  align-self: flex-start;
  list-style-type: none;
  padding: 15px;
}

.errors-list > li {
  margin-bottom: 10px;
}

.errors-list > li > span {
  color: red;
  margin-right: 4px;
}

.modal {
  background-color: #ffffff;
  position: relative;
  width: 450px;
  min-height: 200px;
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow-y: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.input-file {
  position: absolute;
  z-index: -1;
  top: 10px;
  left: 8px;
  font-size: 17px;
  color: #b8b8b8;
}

.button-pop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: none;
  color: #f5f8fc;
  backdrop-filter: blur(40.5px);
  background-color: #456bf1;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.button-download {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50%;
  height: 40px;
  flex-shrink: 0;
  border: 1px solid #e2e9ff;
  background-color: #f5f8fc;
  backdrop-filter: blur(40.5px);
  color: #456bf1;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0;
  padding: 0;
  border-radius: 40px;
}

.excel-upload-icon {
  font-size: 20px;
  margin-right: 10px;
}

@media screen and (min-height: 701px) {
  .excel-width {
    max-height: 120%;
  }

  .excel-container {
    height: 65vh;
    max-height: 65vh;
  }
}

@media screen and (min-height: 820px) {
  .excel-width {
    max-height: 120%;
  }

  .excel-btns {
    right: 0;
    bottom: -6%;
  }
}

@media screen and (min-height: 1001px) {
  .excel-width {
    max-height: 120%;
  }

  .excel-btns {
    right: 0;
    bottom: -10%;
  }
}
