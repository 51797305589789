.activity-container {
  box-shadow: 0px 2px 2px 1px #0000000a;
  border: 0.2px solid #b8c4e7;
  width: 100%;
  height: 463px;
  border-radius: 12px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
}

.activity-container > h1 {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  margin-bottom: 20px;
}

.activity-desc {
  font-family: "Merriweather Sans";
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #41475e;
  margin-left: 15px;
}

.activity-desc-name {
  font-weight: 700;
}

.time {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #41475e;
  opacity: 0.8;
}

.empty-view {
  width: 100%;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
}
