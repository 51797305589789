@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.faculty-obe {
  position: relative;
  top: 70px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  overflow-x: hidden;
  padding-bottom: 10%;
  background-color: #fff;
  width: 1120px;
}

.students-bg {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 15px;
  padding-right: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  background-color: #f8f9fe;
}

.students-filters {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.filters-title {
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-weight: 700;
  color: #37464f;
  margin-bottom: 6px;
  margin-left: 10px;
}

.filters {
  font-weight: 900;
  font-size: 16px;
  font-family: "Lato";
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 86% !important;
  outline: none;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 100px;
  height: 45px;
  background-color: #fff;
  box-shadow: 0px 2px 3px 2px #0000000a;
}

.filters:hover {
  background-color: rgb(231, 239, 250);
}

.batches-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 5px;
}

.batches-ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-weight: 900;
  font-size: 14px;
  font-family: "Lato";
  outline: none;
  padding: 10px;
  min-width: 100px;
  max-width: 100px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  margin-right: 10px;
  cursor: pointer;
}

.sem-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 5px;
}

.sem-ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-weight: 900;
  font-size: 14px;
  font-family: "Lato";
  outline: none;
  padding: 10px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  margin-right: 10px;
  cursor: pointer;
}

.batches-ul-active {
  background-color: #456bf1;
  color: #ffffff;
}

.sem-filter > li {
  width: 35px;
  padding: 5px;
}

.performance-filter {
  width: fit-content;
  padding-left: 15px;
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.performance-filter > li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-weight: 900;
  font-size: 14px;
  font-family: "Lato";
  outline: none;
  padding: 10px;
  min-width: 100px;
  max-width: 100px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  margin-left: 12px;
  /* cursor: pointer; */
}

.arrow-buttons {
  box-shadow: 0px 2px 3px 2px #0000000a;
  width: 30px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2px;
}

.table-list-count {
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-weight: 700;
  color: #37464f;
}

.arrow-buttons-table {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #ecf0ff;
}

.right-btn {
  transform: rotate(180deg);
}

.card-container {
  padding: 12px;
  padding-top: 20px;
  border: 0.2px solid #b8c4e7;
  width: 190px;
  height: 120px;
  border-radius: 12px;
  box-shadow: 0px 2px 2px 1px #0000000a;
  margin-right: 10px;
}

.card-container > h1 {
  color: #37464f;
  font-family: "Lato";
  font-weight: 900;
  font-size: 30px;
  margin-left: 15px;
}

.card-container > div > p {
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 12px;
  color: #8f949f;
}

.card-container > div > h2 {
  font-family: "Lato";
  font-weight: 900;
  font-size: 14px;
  color: #606981;
  margin-left: 15px;
}

.attainment-card {
  cursor: pointer;
  padding: 15px;
  width: 290px;
  height: 120px;
  box-shadow: 0px 2px 2px 1px #0000000a;
  border: 0.2px solid #b8c4e7;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-right: 10px;
}

.attainment-card-active {
  border: 2px solid #3d65f4;
  background-color: #eff3ff;
  box-shadow: 2px 2px 15px 1px #00000040;
}

.attainment-card > div > h1 {
  color: #37464f;
  font-family: "Lato";
  font-weight: 900;
  font-size: 30px;
  margin-left: 10px;
}

.attainment-card > div > span {
  color: #8f949f;
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 12px;
}

.attainment-card > p {
  color: #27ae7a;
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
}

.attainment-card > div > h2 {
  font-family: "Lato";
  font-weight: 900;
  font-size: 14px;
  color: #606981;
}

.attainment-filter {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 800;
  color: #456bf1;
  font-style: normal;
  border: 1.5px solid #f1f3f5;
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 92%;
  background-color: #ecf0ff;
  width: 70px;
  outline: none;
  padding: 5px;
}

.attainment-list {
  width: 46%;
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
}

.std-attainment-graph {
  width: 53%;
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
  margin-right: 5px;
}

.attainment-list-filter {
  width: 80px;
  background-color: transparent;
  border: none;
}

.attainment-list > ul > li {
  border: 0.25px solid #456bf1;
  border-radius: 10px;
  color: #606981;
  width: fit-content;
  padding: 6px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Lato";
  font-weight: 700;
  font-size: 16px;
  margin: 8px;
  background-color: #f8f9fe;
}

.course-list-active {
  border: 2px solid #456bf1 !important;
}

.attainment-list > ul > li > p {
  font-weight: 600;
  font-size: 14px;
}

.table-btns {
  color: #456bf1;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400px;
}

.vertical-line {
  border: 1px solid #b8c4e7;
  height: 100%;
}

.students-container {
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.5px solid #b8c4e7;
  border-radius: 12px;
  width: 97.7%;
  margin-top: 15px;
  margin-left: 20px;
  background-color: #ffffff;
}

.students-table-container {
  width: 100%;
}

.student-link {
  display: inline-block;
}

.students-table-container > tr {
  background-color: #f5f8fc;
  height: 50px;
}

.students-table-container > tr:nth-child(2n) {
  background-color: #ffffff;
}

.students-table-container > tr:hover {
  background-color: rgb(234, 234, 242);
  cursor: pointer;
}

.students-table-container > tr:first-child {
  background-color: #f5f8fc;
  height: 50px;
  cursor: auto;
}

.students-table-container > tr:last-child > td:first-child {
  border-bottom-left-radius: 12px;
}

.students-table-container > tr:last-child > td:last-child {
  border-bottom-right-radius: 12px;
}

.students-table-container > tr > th {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  color: #8f949f;
}

.students-table-container > tr > td {
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-weight: 400;
  min-width: 12%;
  max-width: 180px;
  text-align: center;
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-wrap: nowrap;
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  text-transform: capitalize;
}

.labels {
  color: #8f949f;
  font-size: 14px;
  font-family: "Merriweather Sans";
  line-height: 18px;
  margin-bottom: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay-card {
  background-color: #fcfdff;
  width: 70%;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
  right: 0;
  position: absolute;
}

.overlay-title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #606981;
}

.filter-txt {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 0px;
  outline: none;
  min-width: 250px;
  max-width: 250px;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  outline: none;
  min-width: 250px;
  max-width: 250px;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.filter-select:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-select:focus {
  color: #606981;
  opacity: 1;
}

.filter-txt::placeholder {
  opacity: 0.5;
}

.button {
  display: flex;
  justify-content: center;
  padding: 10px 18px;
  border-radius: 40px;
  background: #456bf1;
  color: #fff;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  width: 140px;
  margin-right: 20px;
}
