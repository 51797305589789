@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.label {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #000;
  font-style: normal;
  margin-bottom: 10px;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #000;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 10px;
  outline: none;
}

.filter-select-reg {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #000;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  outline: none;
}

.filter-select-reg::placeholder {
  opacity: 0.5;
}

.filter-select-reg:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-select-reg:focus {
  color: #000;
  opacity: 1;
}

.course-type-label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.type-labels {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.project-type-label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.weightages-info {
  font-family: Merriweather Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #000;
  margin-top: 8px;
  margin-bottom: 10px;
}

.hr-line {
  border: 1px solid #e9e9e9;
  margin-bottom: 10px;
  margin-top: 10px;
}
