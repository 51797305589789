@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.labels {
  font-weight: 400;
  font-size: 16px;
  font-family: "Merriweather Sans";
  text-transform: capitalize;
}

.text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.modal {
  background-color: #ffffff;
  position: relative;
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
