@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.setup-campus-btn {
  width: 174px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #1c60ff;
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: 'Lato';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  margin-top: 10px;
}
