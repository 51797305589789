@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.account-container {
  width: 1090px;
  margin-left: 25px;
  margin-right: 13px;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 0;
  background: #ffffff;
  position: relative;
  top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.info-container {
  padding: 30px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 8.411px;
  border: 0.2px solid #b8c4e7;
  background: #fff;
  box-shadow: 0px 2px 2.1027026176452637px 1.4018018245697021px
    rgba(0, 0, 0, 0.04);
}

.regulation-main-heading {
  color: #040c58;
  font-family: "Lato";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-bottom: 25px;
}

.label {
  color: #8f949f;
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.462px;
  letter-spacing: -0.106px;
}

.info-input {
  border-radius: 8.41px;
  border: 1px solid #9c9c9c;
  width: 330px;
  height: 44px;
  color: #606981;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-top: 6px;
  outline: none;
}

.section-input {
  width: 200px;
}

.dept-info-input {
  border-radius: 8.41px;
  border: 1px solid #9c9c9c;
  width: 250px;
  height: 44px;
  color: #606981;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-top: 6px;
  outline: none;
}

.info-input::placeholder {
  opacity: 0.5;
}

.dept-info-input::placeholder {
  opacity: 0.5;
}

.info-input-text-area {
  border-radius: 8.41px;
  border: 1px solid #9c9c9c;
  color: #606981;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-top: 6px;
  outline: none;
}

.info-input-text-area::placeholder {
  opacity: 0.5;
}

.insert-btn {
  width: 182px;
  height: 44px;
  border-radius: 10px;
  background: #3d65f4;
  backdrop-filter: blur(40.5px);
  color: #fff;
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.462px;
  letter-spacing: -0.106px;
}

.insert-batch-btn {
  width: 150px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #3d65f4;
  backdrop-filter: blur(40.5px);
  color: #fff;
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.462px;
  letter-spacing: -0.106px;
}

.regulation-btn {
  width: 76px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #1c60ff;
  background: #e2e9ff;
  backdrop-filter: blur(40.5px);
  color: #040c58;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.462px;
  letter-spacing: -0.106px;
  margin-right: 12px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reg-name {
  color: #040c58;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24.462px;
  letter-spacing: -0.106px;
}

.reg-batch {
  color: #1c60ff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.462px; /* 152.885% */
  letter-spacing: -0.106px;
  margin-top: 5px;
}

.department-btn {
  min-width: 100px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #1c60ff;
  background: #e2e9ff;
  backdrop-filter: blur(40.5px);
  color: #040c58;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.462px;
  letter-spacing: -0.106px;
  margin-bottom: 10px;
  margin-right: 12px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-txt {
  border-radius: 8.41px;
  border: 1px solid #9c9c9c;
  width: 330px;
  height: 44px;
  color: #606981;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-top: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  outline: none;
}

.dept-filter-txt {
  border-radius: 8.41px;
  border: 1px solid #9c9c9c;
  width: 200px;
  height: 44px;
  color: #606981;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-top: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  outline: none;
}

.filter-txt::placeholder {
  opacity: 0.5;
}

.filter-txt:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-txt:focus {
  color: #606981;
  opacity: 1;
}

.dept-filter-txt::placeholder {
  opacity: 0.5;
}

.dept-filter-txt:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.dept-filter-txt:focus {
  color: #606981;
  opacity: 1;
}

.home {
  width: 1280px;
}

.existing-pso-container {
  display: flex;
  align-items: flex-start;
}

.existing-pso-box {
  width: 900px;
  min-height: 144px;
  max-height: 144px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #1c60ff;
  backdrop-filter: blur(40.5px);
  color: #040c58;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.462px; /* 152.885% */
  letter-spacing: -0.106px;
  padding: 10px;
  margin-bottom: 14px;
}

.pso-btn {
  width: 88px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #1c60ff;
  background: #e2e9ff;
  backdrop-filter: blur(40.5px);
  color: #040c58;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.462px;
  letter-spacing: -0.106px;
  margin-right: 12px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-po-container {
  background-color: #d4e5ff;
  border-radius: 7px;
  margin-bottom: 40px;
  width: 120px;
  height: 40px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-select-po {
  font-size: 16px;
  font-family: "Merriweather Sans";
  font-weight: 900;
  color: #182b68;
  font-style: normal;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 90%;
  outline: none;
  width: 100%;
  margin: 0;
  padding-left: 15px;
  cursor: pointer;
}

.modal {
  background-color: #ffffff;
  position: relative;
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
