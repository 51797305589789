.student-public-table {
  border: 1px solid #456bf1;
  border-radius: 5px;
  width: 100%;
  font-family: "Lato";
}

.student-public-table > thead > tr > th {
  border: 0.5px solid #456bf1;
  padding: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #040c58;
  background-color: #eff3ff;
}

.student-public-table > thead > tr > th:first-child {
  width: 10%; /* Set 10% width for the first column */
}

.student-public-table > thead > tr > th:not(:first-child) {
  width: 10%; /* Set remaining 90% width for other columns */
}

.student-public-table > tbody > tr > td {
  border: 0.5px solid #456bf1;
  padding: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  width: 30%;
  text-align: center;
}

.student-public-table > tbody > tr > .input-col {
  width: 15%;
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  line-height: 18px;
  padding: 6.5px;
  text-align: center;
}

.student-public-table > tbody > tr > td {
  width: 10%;
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  line-height: 18px;
  padding: 6.5px;
  text-align: center;
}

.student-public-table > tbody > tr > td > input {
  width: 95%;
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  line-height: 18px;
  border: 1.5px solid #e8e9ea;
  padding: 6.5px;
  text-align: center;
}

.plus-btn {
  border: 1px solid #e2e9ff;
  background-color: #f5f8fc;
  font-family: "Lato";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  color: #456bf1;
  width: 60px;
  border-radius: 12px;
  padding: 2px;
  margin-top: 10px;
  margin-left: 10px;
  align-self: flex-end;
  z-index: 10;
}

.save-btn {
  border: 1px solid #e2e9ff;
  background-color: #f5f8fc;
  font-family: "Lato";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  color: #456bf1;
  width: 130px;
  border-radius: 12px;
  padding: 5px;
  margin-top: 30px;
  align-self: flex-end;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 10px);
  outline: none;
  width: 95%;
  padding: 6.5px;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.filter-select:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-select:focus {
  color: #606981;
  opacity: 1;
}
