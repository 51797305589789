.course-art-table {
  border: 1px solid #456bf1;
  border-radius: 5px;
  width: 100%;
  font-family: "Lato";
  padding-right: 0;
  margin-top: 20px;
}

.course-art-table > tr > th {
  border: 0.5px solid #456bf1;
  padding: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #040c58;
  background-color: #eff3ff;
  width: 20px;
}

.course-art-table > tr > td {
  border: 0.5px solid #456bf1;
  padding: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
}

.span-sem {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #37464f;
}

.sem-button-active {
  width: 35px;
  height: 44px;
  top: 442px;
  left: 418px;
  gap: 0px;
  background-color: #456bf1;
  color: #e2e9ff;
  border-radius: 12px 12px 12px 12px;
  border: 0.25px 0.25px 0.25px 0.25px;
  border: 0.25px solid #e2e9ff;
  font-weight: 600;
  box-shadow: 0px 2px 3px 2px #0000000a;
  margin: 8px 8px 0px 0px;
}

.sem-button {
  width: 40px;
  height: 44px;
  border-radius: 12px 12px 12px 12px;
  border: 0.25px 0.25px 0.25px 0.25px;
  background: #ffffff;
  border: 0.25px solid #e2e9ff;
  color: #606981;
  font-weight: 600;
  box-shadow: 0px 2px 3px 2px #0000000a;
  margin: 8px 8px 0px 0px;
  padding-left: 2px;
  padding-right: 2px;
}

.course-info {
  width: 45%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  color: #606981;
}

.course-info > span {
  margin-right: 15px;
  color: #606981;
}

.label-box {
  width: 90%;
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  line-height: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: 1.5px solid #e8e9ea;
  padding: 6.5px;
  outline: none;
  text-align: center;
  height: 34px !important;
}

.table {
  width: 100%;
}

.table-scroll {
  position: relative;
  width: 300%;
  margin: auto;
  overflow: auto;
}

.table-scroll table {
  width: 100%;
  min-width: 150%;
  margin: auto;
  border-collapse: collapse;
  border-spacing: 0;
}

.table-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.floatarr {
  transform: rotate(180deg);
}

.save-btn {
  border: 1px solid #e2e9ff;
  background-color: #f5f8fc;
  font-family: "Lato";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  color: #456bf1;
  width: 130px;
  border-radius: 12px;
  padding: 5px;
  margin-top: 30px;
  align-self: flex-end;
}

.empty-view {
  width: 100%;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
}
