@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.faculty-obe {
  position: relative;
  top: 70px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  margin-top: 30px;
  overflow-x: hidden;
  padding-bottom: 40px;
}

.dashboard-periodwisebar-bg {
  width: 1090px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px;
  background: #ffffff;
  border: 0.25px solid #e2e9ff;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  position: relative;
  top: 10px;
  box-sizing: border-box;
  margin-right: 10px;
}

.filters {
  font-weight: 700;
  font-size: 14px;
  font-family: "Merriweather Sans";
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 92% !important;
  outline: none;
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.filters:hover {
  background-color: rgb(231, 239, 250);
}

.table-labels {
  font-weight: 700;
  font-size: 16px;
  font-family: "Lato";
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  animation: example 0.2s linear;
}

.overlay-card {
  background-color: #fcfdff;
  width: 70%;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

.list li:nth-child(odd) {
  background-color: #f5f8fc;
}

@keyframes example {
  from {
    width: 0vw;
  }

  to {
    width: 100vw;
  }
}

.heading {
  color: #606981;
  font-size: 24px;
  font-family: "Lato";
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.label {
  color: #606981;
  font-size: 18px;
  font-family: "Lato";
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.25px;
}

.label-active {
  color: #456bf1;
  font-size: 19px;
  font-family: "Lato";
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.25px;
}
