@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.login-page {
  /* width: 1280px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* height: 100vh; */
}

.span-container {
  width: 1280px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
}

.form-container {
  background: linear-gradient(180deg, #0575e6 0%, #11175c 84.79%, #040c58 100%);
  width: 750px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ellip {
  position: fixed;
  align-self: flex-end;
}

.logo {
  position: fixed;
  opacity: 0.1;
  margin-left: 250px;
}

.text {
  position: fixed;
  margin-left: 150px;
}

.para {
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  height: 27px;
  width: 341px;
  font-family: "Poppins";
  line-height: 27px;
}

.read-more-btn {
  background-color: #0575e6;
  color: #ffffff;
  width: 130px;
  height: 35px;
  border-radius: 30px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-top: 20px;
}

.login-form {
  width: 530px;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.greet-heading {
  color: #333333;
  font-size: 30px;
  font-weight: 700;
  font-family: "Poppins";
  line-height: 45px;
  align-self: flex-start;
  position: relative;
  left: 80px;
}

.paragraph {
  margin-bottom: 30px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  align-self: flex-start;
  position: relative;
  left: 80px;
}

.input-bg {
  border: 1px #eeeeee solid;
  border-radius: 30px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #333333;
  margin-bottom: 16px;
  width: 380px;
  height: 50px;
}

.input {
  background-color: transparent;
  border: none;
  width: 380px;
  height: 50px;
  outline: none;
  color: #333333;
  margin: 0;
  padding-left: 5px;
  padding-right: 5px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.icons {
  margin: 0;
  margin-right: 8px;
  margin-left: 10px;
  color: #333333;
  width: 21px;
  height: 18px;
}

.login-btn {
  color: #ffffff;
  background-color: #0575e6;
  border: none;
  border-radius: 30px;
  font-weight: 600;
  font-size: 20px;
  padding: 6px;
  width: 280px;
  margin-top: 14px;
  line-height: 30px;
  font-family: "Poppins";
  letter-spacing: 1px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-btn-disabled {
  color: #ffffff;
  background-color: gray;
  border: none;
  border-radius: 30px;
  font-weight: 600;
  font-size: 20px;
  padding: 6px;
  width: 280px;
  margin-top: 14px;
  line-height: 30px;
  font-family: "Poppins";
  letter-spacing: 1px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot {
  color: #333333;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-top: 17px;
  border: none;
  background-color: transparent;
}

.error {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.forgot-desc {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
}

.hide-icon {
  font-size: 22px;
}
