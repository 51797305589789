@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.pdf-bg {
  background-color: #ffffff;
}

.dashboard-periodwisebar-bg {
  width: 1090px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 20px;
  padding-bottom: 30px;
  background: #ffffff;
  border: 0.25px solid #e2e9ff;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  position: relative;
  top: 60px;
  box-sizing: border-box;
  margin-top: 5px;
  margin-bottom: 10%;
}

.course-title {
  font-family: "Lato";
  font-weight: 900;
  font-size: 16px;
  color: #606981;
  letter-spacing: 0.5px;
  font-style: normal;
}

.course-analysis-title {
  font-family: "Lato";
  font-weight: 900;
  font-size: 14px;
  color: #606981;
  letter-spacing: 0.5px;
  font-style: normal;
}

.filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 45%;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 700;
  color: #456bf1;
  font-style: normal;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 100%;
  outline: none;
}

.table-th {
  font-family: "Merriweather Sans";
  font-weight: lighter;
  font-style: normal;
  font-size: 14px;
  padding: 0;
}

.table-td {
  font-family: "Lato";
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
}

.floatarr {
  /* right: -99%; */
  transform: rotate(180deg);
}

.table {
  width: 100%;
  display: flex;
  z-index: 1;
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 0;
  margin: auto;
  overflow: auto;
  border: #456bf1 1px solid;
}

.table-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.table-scroll table {
  width: 100%;
  min-width: 100%;
  margin: auto;
  border-collapse: collapse;
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}
.table-scroll th,
.table-scroll td {
  border: #456bf1 1px solid;
  vertical-align: top;
}
.table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
/* safari and ios need the tfoot itself to be position:sticky also */
.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 4;
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
}

.comments {
  flex-direction: row;
  justify-content: space-between;
}

@media print {
  .download-btn {
    display: none;
  }

  .page {
    height: 1000px;
  }

  .comments {
    display: flex;
  }

  @page {
    margin: 30px;
  }

  body {
    margin: 0.5cm;
  }
}
