@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.faculty-obe {
  position: relative;
  top: 70px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  margin-top: 18px;
  overflow-x: hidden;
  padding-bottom: 40px;
  min-width: 1120px;
}

.dashboard-periodwisebar-bg {
  width: 1090px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px;
  padding-right: 50px;
  background: #ffffff;
  border: 0.25px solid #e2e9ff;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  position: relative;
  top: 10px;
  box-sizing: border-box;
  margin-right: 10px;
}

.course-analysis-title {
  font-family: "Lato";
  font-weight: 700;
  font-size: 21px;
  color: #040c58;
  letter-spacing: 0.5px;
  font-style: normal;
}

.students-bg {
  width: 1120px;
  padding-bottom: 10px;
  padding-top: 15px;
  padding-right: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #f8f9fe;
}

.students-filters {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.filters-title {
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-weight: 700;
  color: #37464f;
  margin-bottom: 6px;
  margin-left: 10px;
}

.filters {
  font-weight: 900;
  font-size: 16px;
  font-family: "Lato";
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 86% !important;
  outline: none;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 120px;
  text-align: center;
  height: 45px;
  background-color: #fff;
  box-shadow: 0px 2px 3px 2px #0000000a;
}

.filters:hover {
  background-color: rgb(231, 239, 250);
}

.batches-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 5px;
}

.batches-ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-weight: 900;
  font-size: 14px;
  font-family: "Lato";
  outline: none;
  padding: 10px;
  min-width: 100px;
  max-width: 100px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  margin-right: 10px;
  cursor: pointer;
}

.sem-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 5px;
}

.sem-ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-weight: 900;
  font-size: 14px;
  font-family: "Lato";
  outline: none;
  padding: 10px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  margin-right: 10px;
  cursor: pointer;
}

.batches-ul-active {
  background-color: #456bf1;
  color: #ffffff;
}

.sem-filter > li {
  width: 35px;
  padding: 5px;
}

.arrow-buttons {
  box-shadow: 0px 2px 3px 2px #0000000a;
  width: 30px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2px;
}

.right-btn {
  transform: rotate(180deg);
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  text-transform: capitalize;
}
