.bg-containers {
  border: 1px solid #e2e9ff;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
}

.co-list {
  border: 0.5px solid #456bf1;
  border-top: none;
  padding: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-family: "Lato";
  font-size: 14px;
}

.co-statement {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.3499999940395355px;
  text-align: left;
  margin-top: 10px;
}

.art-matrix-title {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 11.52px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #606981;
}

.marks-dist-table > tr > th {
  background-color: #eff3ff;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #040c58;
}

.marks-dist-table > tr > td {
  background-color: #fff;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #182b68;
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  text-transform: capitalize;
}

.floatarr {
  transform: rotate(180deg);
}

.matrix {
  font-size: 16px;
  font-family: "Merriweather Sans";
  font-weight: 600;
  letter-spacing: 0.25px;
}

.matrix > th {
  background-color: #eff3ff;
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #040c58;
}

.matrix > thead > tr > th {
  background-color: #eff3ff;
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #040c58;
  border-right: solid 1px #3b82f6;
}

.art-co-names {
  background-color: #fff;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #182b68;
}

.matrix > tbody > tr > td {
  background-color: #fff;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #182b68;
  border-right: solid 1px #3b82f6;
}

.table {
  width: 100%;
  display: flex;
}

.table-scroll {
  position: relative;
  width: 300%;
  margin: auto;
  overflow: auto;
}

.table-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.table-scroll table {
  width: 100%;
  min-width: 200%;
  margin: auto;
  border-collapse: collapse;
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}
.table-scroll th,
.table-scroll td {
  border: none;
  vertical-align: top;
  background-color: #fff;
}
.table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
/* safari and ios need the tfoot itself to be position:sticky also */
.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 4;
}
