.analysis-container {
  box-shadow: 0px 7px 9px 0px #20175a1a;
  background-color: #fff;
}

.bg-containers {
  border: 1px solid #e2e9ff;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
}

.bar-chart {
  width: 49%;
  border: 0.25px solid #e2e9ff;
  border-radius: 12px;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 3px 2px #0000000a;
}

.bar-chart > p {
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  line-height: 11.52px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #606981;
  margin: 10px;
  margin-bottom: 30px;
}

.assmnt-type-heading {
  font-family: Merriweather Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #8f949f;
}

.assmnt-title {
  font-family: Merriweather Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #606981;
  margin-top: 20px;
  margin-bottom: 35px;
}

.table-title {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 11.52px;
  letter-spacing: 0.5px;
  color: #606981;
}

.qp-analysis-table th {
  border: 0.5px solid #456bf1;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  color: #040c58;
  background-color: #eff3ff;
  padding: 10px;
}

.qp-analysis-table td {
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.4371246099472046px;
  border: 0.5px solid #456bf1;
  padding: 10px;
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  text-transform: capitalize;
}

.back-button {
  padding: 6px 25px;
  border-radius: 40px;
  background: #f5f8fc;
  color: #456bf1;
  border: 1px solid #e2e9ff;
  margin-right: 30px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
}

.template-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 30px;
}

.template-ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-weight: 900;
  font-size: 14px;
  font-family: "Lato";
  outline: none;
  padding: 10px;
  min-width: 100px;
  max-width: 100px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  margin-right: 10px;
  cursor: pointer;
}

.template-ul-active {
  background-color: #456bf1;
  color: #ffffff;
}
