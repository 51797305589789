@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap");

.nav-bar {
  width: 1090px;
  height: 89px;
  position: fixed;
  margin-left: 15px;
  margin-right: 15px;
  padding-right: 20px;
  padding-left: 15px;
  z-index: 1;
  background-color: #ffffff;
}

.tab-bar {
  width: 450px;
  height: 44px;
  border-radius: 40px;
  font-family: "Lato";
  font-weight: 700;
  font-size: 18px;
  color: #606981;
  padding-left: 15px;
  padding-right: 15px;
  letter-spacing: 0.5px;
}

.arrows-container {
  width: 76px;
  height: 32px;
}

.arrow-bg {
  background-color: #ecf0ff;
  border-radius: 32px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-el-container {
  font-family: "Merriweather Sans";
  border: solid 1px #d4d8e9;
  border-radius: 44px;
  background-color: #f3f4f8;
  width: 278px;
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: 14px;
}

.input-el {
  background-color: transparent;
  border: none;
  outline: none;
  text-overflow: ellipsis;
  color: #000;
  font-weight: 400;
  font-size: 14px !important;
}

.input-el::placeholder {
  color: #878994;
}

.count {
  font-size: 11px;
  font-weight: 500;
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

.dropdown {
  width: 267px;
  height: 236px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 0.5px solid #456bf1;
  background: #fff;
  box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 15px;
  top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.dropdown-admin {
  height: 320px;
}

.li-text {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-left: 20px;
  color: #606981;
}

.li-items {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
}

.logout-btn {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #f86e7f;
  margin-bottom: 10px;
}
