.budget-expenditure-summary-table {
  border: 1px solid #456bf1;
  border-radius: 5px;
  width: 100%;
  font-family: "Lato";
  margin-top: 30px;
}

.budget-expenditure-summary-table > thead > tr > th {
  border: 0.5px solid #456bf1;
  padding: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #040c58;
  background-color: #eff3ff;
  width:20%;
  
}

.budget-expenditure-summary-table > thead > tr > td {
  border: 0.5px solid #456bf1;
  padding: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #040c58;
  background-color: #eff3ff;
  width:10%;
  
}

.budget-expenditure-summary-table > thead> tr> th> input {
  width: 100px; /* Adjust input width */
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  border: 1.5px solid #e8e9ea;
  border-radius: 5px;
  padding: 6.5px;
  text-align: center;
}


.budget-expenditure-summary-table > tbody > tr > td {
  border: 0.5px solid #456bf1;
  padding: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;

  font-family: "Lato";
}

.budget-expenditure-summary-table > tbody > tr > .input-col {
 
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  line-height: 18px;
  padding: 6.5px;
  text-align: center;
}

.budget-expenditure-summary-table > tbody > tr > td {
  width: 10%;
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  line-height: 18px;
  padding: 6.5px;
  text-align: center;
}

.budget-expenditure-summary-table > tbody > tr > td > input {
  width: 95%;
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  line-height: 18px;
  border: 1.5px solid #e8e9ea;
  padding: 6.5px;
  text-align: center;
}



.save-btn {
  border: 1px solid #e2e9ff;
  background-color: #f5f8fc;
  font-family: "Lato";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  color: #456bf1;
  width: 130px;
  border-radius: 12px;
  padding: 5px;
  margin-top: 30px;
  align-self: flex-end;
}
