.arrow-buttons-table {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #ecf0ff;
}

.right-btn {
  transform: rotate(180deg);
}
