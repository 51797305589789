@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.account-container {
  width: 1090px;
  margin-left: 25px;
  margin-right: 13px;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 0;
  background: #ffffff;
  position: relative;
  top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.info-container {
  padding: 30px;
  padding-bottom: 5px;
  width: 100%;
  border-radius: 8.411px;
  border: 0.2px solid #b8c4e7;
  display: flex;
  flex-direction: column;
}

.migrate-title {
  font-family: Lato;
  font-size: 19px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #606981;
}

.label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8f949f;
  margin-top: 10px;
}

.label-sem {
  color: #000;
}

.label-select {
  color: #3d65f4;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-right: 0;
  margin-left: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 14px) 15px !important;
  outline: none;
  width: 100%;
  height: 40px;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.filter-select:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-select:focus {
  color: #606981;
  opacity: 1;
}

.sec-check {
  height: 18px;
  width: 20px;
  cursor: pointer;
}

.sec-check-label {
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-weight: 400;
  color: #606981;
  cursor: pointer;
}

.filter-container {
  width: 50%;
}

.filter-el {
  width: 45%;
}

.filter-el-sem {
  width: 100%;
}

.arrow {
  width: 20%;
  position: absolute;
  left: 32%;
}

.sem-container {
  width: 90%;
}

.sem-courses-container {
  height: 50vh;
  overflow-y: auto;
}
