.dashboard-container {
  position: relative;
  top: 105px;
  left: 20px;
  padding: 4px;
  display: flex;
  width: 1120px;
  padding-bottom: 3%;
  display: flex;
  flex-direction: column;
}

.metric-value {
  font-family: "Merriweather Sans";
  font-size: 18px;
  font-weight: 400;
  color: #6276e5;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 100%;
}

.progress-bar-container {
  width: 100%;
  height: 12px;
  background-color: #e2e9ff;
  border-radius: 5px;
  margin-top: 5px;
}

.progress-bar {
  background-color: #6276e5;
  height: 12px;
  border-radius: 10px;
}

.profile-dp-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: 8%;
  width: 300px;
}

.profile-dp-container:first-child {
  margin-left: 0;
}

.profile-dp {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-left: -15px;
  border: 3px solid #ffffff;
}

.profile-count {
  color: #121212;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
}

.search-input-container {
  box-shadow: 0px 2px 3px 2px #0000000a;
  border-width: 0px;
  border-style: solid;
  border-color: #e2e9ff;
  width: 300px;
  height: 50px;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  padding: 10px;
  padding-right: 2px;
  display: flex;
  align-items: center;
  border-radius: 12px;
}

.search-input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  margin-left: 15px;
  margin-right: 20px;
}

.dotted-plus {
  font-size: 28px;
  font-weight: 500;
  color: #c9c9c9;
  border: 2px dashed #d0d0d0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
  margin-left: 30px;
  margin-top: 5px;
}

.metrics-status-card {
  width: 1090px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin-top: 25px;
}

.metrics-status-card > li {
  width: 18.5%;
  height: 120px;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px;
}

.metrics-status-card > li > h1 {
  font-family: "Lato";
  font-size: 45px;
  font-weight: 700;
  text-align: left;
  color: #37464f;
}

.metrics-status-card > li > h1 > span {
  font-family: "Merriweather Sans";
  font-size: 18px;
  font-weight: 400;
  text-align: right;
  color: #8f949f;
  margin-left: 10px;
}

.metrics-status-card > li > h2 {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.5px;
  color: #606981;
}

.metrics-list {
  width: 1090px;
  margin-top: 25px;
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.5px solid #b8c4e7;
  border-radius: 12px;
  border-collapse: separate;
  border-spacing: 0;
}

.metrics-list > thead > tr > th {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #8f949f;
  padding: 15px;
  border: none;
}

.metrics-list > tbody > tr > td {
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #606981;
  padding: 15px;
  width: 20%;
  border: none;
  border-top: 0.5px solid #e8edff;
}

.metrics-list > tbody > tr > td:nth-child(2) {
  width: 28%;
}

.metrics-list > tbody > tr > td:nth-child(3) {
  width: 13%;
}

.metrics-list > tbody > tr > td:first-child {
  width: 6%;
}

.metric-table-name {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
  text-wrap: nowrap;
  padding-right: 15px;
}

.metric-table-date {
  min-width: 115px;
  max-width: 115px;
  text-align: left;
  padding: 5px !important;
}

.metrics-list > tbody > tr {
  position: relative;
}

.metrics-list > thead > tr {
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  animation: example 0.2s linear;
}

.overlay-card {
  background-color: #fcfdff;
  width: 70%;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

@keyframes example {
  from {
    width: 0vw;
  }

  to {
    width: 100vw;
  }
}

.project-main-heading {
  font-family: Lato;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #606981;
}

.project-details-container {
  background: #f5f8fc;
  padding: 10px;
  margin-top: 15px;
  padding: 30px;
  padding-top: 20px;
}

.project-details-heading {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #606981;
  margin-bottom: 25px;
}

.label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8f949f;
  margin-bottom: 10px;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  outline: none;
}

.filter-txt {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  outline: none;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.filter-select:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-select:focus {
  color: #606981;
  opacity: 1;
}

.filter-txt::placeholder {
  opacity: 0.5;
}

.create-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #e2e9ff;
  color: #f5f8fc;
  backdrop-filter: blur(40.5px);
  background-color: #456bf1;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
}

.cancel-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #e2e9ff;
  color: #456bf1;
  backdrop-filter: blur(40.5px);
  background-color: #f5f8fc;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
}

.all-settings {
  font-family: Lato;
  font-size: 18px;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #3d65f4;
  display: flex;
  align-items: center;
}

.empty-view {
  width: 100%;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
}

.metric-table-name {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
  text-wrap: nowrap;
  padding-right: 15px;
  text-align: center !important;
}
