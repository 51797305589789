.existing-pso-box {
  width: 100%;
  min-height: 144px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #1c60ff;
  backdrop-filter: blur(40.5px);
  color: #040c58;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.462px;
  letter-spacing: -0.106px;
  padding: 10px;
  margin-bottom: 14px;
}

.pso-btn {
  width: 80px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #1c60ff;
  background: #e2e9ff;
  backdrop-filter: blur(40.5px);
  color: #040c58;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.462px;
  letter-spacing: -0.106px;
  margin-right: 12px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-btn {
  border: 1px solid #e2e9ff;
  background-color: #f5f8fc;
  font-family: "Lato";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  color: #456bf1;
  width: 130px;
  border-radius: 12px;
  padding: 5px;
  margin-top: 30px;
  align-self: flex-end;
}
