.bg-containers {
  border: 1px solid #e2e9ff;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.obs-title {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: left;
  color: #606981;
}

.obs-textarea {
  width: 100%;
  height: 153px;
  background: #f5f8fc;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border: 1.27px solid #f1f3f5;
  outline: none;
  padding: 10px;
  margin-top: 10px;
}

.obs-textarea::placeholder {
  opacity: 0.5;
}

.obs-button {
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  background-color: #3d65f4;
  width: 170px;
  height: 38px;
  border-radius: 10px;
  align-self: flex-end;
  margin-top: 50px;
}
