.analysis-container {
  width: 1104px;
  border: 1.5px solid #e2e9ff;
  background-color: #ffff;
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: 20px;
  left: -0.5px;
}

.bg-container {
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  background-color: #f9fbff;
  position: relative;
  width: 1104px;
  border-top: 0;
}

.course-info {
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #040c58;
  width: 45%;
}

.structure-info-heading {
  font-family: "Lato";
  font-size: 13.5px;
  font-weight: 400;
  line-height: 19.36px;
  color: #606981;
}

.structure-info {
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.36px;
  color: #606981;
  margin-top: 8px;
}

.report-btn {
  font-family: "Merriweather Sans";
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  background-color: #456bf1;
  color: #ffffff;
  border: 1px solid #e2e9ff;
  border-radius: 5px;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  letter-spacing: 1px;
  position: absolute;
  right: 12px;
}

.analysis-tabs-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-left: 10px;
  padding-bottom: 8px;
  overflow-x: scroll;
  width: 95%;
}

.analysis-tabs-ul > li {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 700;
  line-height: 11.52px;
  letter-spacing: 0.25px;
  color: #464d90;
  margin-right: 50px;
  cursor: pointer;
  text-wrap: nowrap;
}

.tab-active {
  color: #040c58 !important;
  font-weight: bolder !important;
  letter-spacing: 0.5px !important;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 5px;
}

.students-bg {
  width: 1120px;
  padding-bottom: 10px;
  padding-top: 15px;
  padding-right: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  background-color: #f8f9fe;
}

.students-filters {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.filters-title {
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-weight: 700;
  color: #37464f;
  margin-bottom: 6px;
  margin-left: 10px;
}

.filters {
  font-weight: 900;
  font-size: 16px;
  font-family: "Lato";
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 86% !important;
  outline: none;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 120px;
  text-align: center;
  height: 45px;
  background-color: #fff;
  box-shadow: 0px 2px 3px 2px #0000000a;
}

.filters:hover {
  background-color: rgb(231, 239, 250);
}

.batches-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 5px;
}

.batches-ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-weight: 900;
  font-size: 14px;
  font-family: "Lato";
  outline: none;
  padding: 10px;
  min-width: 100px;
  max-width: 100px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  margin-right: 10px;
  cursor: pointer;
}

.sem-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 5px;
}

.sem-ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-weight: 900;
  font-size: 14px;
  font-family: "Lato";
  outline: none;
  padding: 10px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  margin-right: 10px;
  cursor: pointer;
}

.batches-ul-active {
  background-color: #456bf1;
  color: #ffffff;
}

.sem-filter > li {
  width: 35px;
  padding: 5px;
}

.arrow-buttons {
  box-shadow: 0px 2px 3px 2px #0000000a;
  width: 30px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2px;
}

.right-btn {
  transform: rotate(180deg);
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 700;
  color: #456bf1;
  font-style: normal;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 92% !important;
  outline: none;
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.filter-select:hover {
  background-color: rgb(231, 239, 250);
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
}

.floatarr {
  transform: rotate(180deg);
}
