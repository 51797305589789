@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.fatcat-logo-container {
  height: 89px;
}

.fatcat-title {
  width: 85px;
}

.side-bar {
  width: 150px;
  height: 100vh;
  position: relative;
  box-shadow: 0.5px 0px 15px rgba(0, 0, 0, 0.05);
}

.sidebar-item-active {
  background-color: #3d65f4;
  width: 150px;
  box-shadow: 0px 7.94872px 15.8974px rgba(0, 0, 0, 0.02);
  cursor: pointer;
}

.sidebar-item {
  background-color: transparent;
  width: 150px;
  box-shadow: 0px 7.94872px 15.8974px rgba(0, 0, 0, 0.02);
  cursor: pointer;
}

.sidebar-text-active {
  font-weight: 700;
  font-style: normal;
  font-family: "Lato";
  font-size: 14px;
  line-height: 16px;
  width: 76px;
  height: 16px;
}

.sidebar-text {
  font-weight: 700;
  font-style: normal;
  font-family: "Lato";
  font-size: 14px;
  line-height: 16px;
  width: 76px;
  height: 16px;
  color: #606981;
}

.logout {
  background-color: #3d65f4;
  width: 100px;
  box-shadow: 0px 7.94872px 15.8974px rgba(0, 0, 0, 0.02);
  cursor: pointer;
  border-radius: 4px;
  padding: 6px;
  font-weight: 700;
  font-style: normal;
  font-family: "Lato";
  font-size: 14px;
}

.feed-back-icon-container {
  background-color: #3d65f4;
  border-radius: 50%;
  padding: 8px;
}

.feed-container {
  position: absolute;
  bottom: 20px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  opacity: 0.5;
}

.feed-container-active {
  opacity: 1;
}

.fb-text {
  color: #000;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.25px;
  margin-left: 12px;
}
