.excel-width {
  width: 100% !important;
  height: 125%;
  max-height: 125%;
  display: flex;
  position: relative;
  top: -40%;
  z-index: 0;
}

.excel-container {
  height: 75vh;
  max-height: 75vh;
  position: relative;
}

.custom-handsontable td,
.custom-handsontable th {
  border-color: #567fff !important;
}

.custom-handsontable-placeholder {
  color: #b8c6f0;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
}

.custom-input-cos-placeholder {
  color: #b8c6f0;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
}

td.custom-cell {
  color: #fff;
  background-color: #456bf1 !important;
  border: 1px solid #567fff;
  border-radius: 2;
  text-align: center;
  height: 25px !important;
  padding-top: 5px !important;
}

.errors-card {
  border: 3px solid #e2e9ff;
  border-radius: 3px;
  width: 35%;
  margin-left: 10px;
  position: relative;
  top: 25%;
  max-height: 75%;
}

.err-count-title {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #e35050;
  background-color: #e2e9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  position: relative;
}

.err-container {
  overflow-y: auto;
  height: 90%;
}

.errors-list {
  color: #606981;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  align-self: flex-start;
  list-style-type: none;
  padding: 15px;
}

.errors-list > li {
  margin-bottom: 10px;
}

.errors-list > li > span {
  color: red;
  margin-right: 4px;
}

.button {
  display: inline-block;
  padding: 10px 18px;
  border-radius: 5px;
  background: #456bf1;
  color: #fff;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.back-button {
  display: inline-block;
  padding: 10px 18px;
  border-radius: 5px;
  background: #f5f8fc;
  color: #456bf1;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid #e2e9ff;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highlighted-cell {
  background-color: rgba(227, 80, 80, 0.3) !important;
}

.readonly-cell {
  border: none !important;
  color: #fff !important;
}

.max-marks-cells {
  border: none !important;
  border-bottom: 1px solid !important;
  text-align: center !important;
}

.roll-cells {
  border-top: 1px solid !important;
  text-align: center !important;
  padding: 0 !important;
  margin-top: 12px !important;
}

.readonly-cell-cols {
  border: none !important;
  padding-bottom: 1px !important;
  border-right: 1px solid !important;
  color: transparent !important;
}

.fill-matrix {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
  position: absolute;
}

.excel-btns {
  position: absolute;
  right: 0;
  bottom: 0;
}

.excel-upload-icon {
  font-size: 20px;
  margin-right: 10px;
}

.modal {
  background-color: #ffffff;
  position: relative;
  width: 450px;
  min-height: 200px;
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow-y: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.input-file {
  position: absolute;
  z-index: -1;
  top: 10px;
  left: 8px;
  font-size: 17px;
  color: #b8b8b8;
}

.button-pop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: none;
  color: #f5f8fc;
  backdrop-filter: blur(40.5px);
  background-color: #456bf1;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.button-download {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50%;
  height: 40px;
  flex-shrink: 0;
  border: 1px solid #e2e9ff;
  background-color: #f5f8fc;
  backdrop-filter: blur(40.5px);
  color: #456bf1;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0;
  padding: 0;
  border-radius: 40px;
}

@media screen and (min-height: 701px) {
  .excel-width {
    max-height: 120%;
    position: relative;
    top: -35%;
  }
}

@media screen and (min-height: 820px) {
  .excel-width {
    max-height: 120%;
    position: relative;
    top: -30%;
  }

  .errors-card {
    top: 20%;
    max-height: 80%;
  }

  .excel-btns {
    position: absolute;
    right: 0;
    bottom: -6%;
  }
}

@media screen and (min-height: 1001px) {
  .excel-width {
    max-height: 120%;
    position: relative;
    top: -200px;
  }

  .errors-card {
    top: 160px;
    max-height: 80%;
  }

  .excel-btns {
    position: absolute;
    right: 0;
    bottom: -10%;
  }
}
