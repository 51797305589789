@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.periodwisebar-title {
  font-family: 'Lato';
  font-weight: 900;
  font-size: 14px;
  color: #606981;
  letter-spacing: 0.5px;
  font-style: normal;
  padding-left: 10px;
}
