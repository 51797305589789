.dashboard-container {
  position: relative;
  top: 105px;
  left: 20px;
  padding: 4px;
  display: flex;
  width: 1120px;
  padding-bottom: 3%;
}

.projects-container {
  width: 60%;
}

.activity-container {
  width: 36%;
  margin-left: 20px;
}

.projects-card {
  border: 0.2px solid #b8c4e7;
  width: 204px;
  height: 165px;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 2px 2px 1px #0000000a;
  margin-left: 10px;
}

.projects-card > h1 {
  font-family: Lato;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.35045045614242554px;
  text-align: left;
  color: #606981;
  position: absolute;
  top: 15px;
}

.projects-card-values {
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  text-align: center;
  line-height: 25px;
  color: #37464f;
  margin-top: 20px;
}

.projects-card-values > span {
  color: #8f949f;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400;
}

.search-input-container {
  box-shadow: 0px 2px 3px 2px #0000000a;
  border-width: 0px, 0px, 0px, 0px;
  border-style: solid;
  border-color: #e2e9ff;
  width: 440px;
  height: 60px;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 20.1px;
  text-align: left;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search-input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  margin-left: 15px;
  margin-right: 20px;
}

.vertical-line {
  border: 2px solid #f5f4fb;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.search-input-title {
  font-family: "Lato";
  font-weight: 700;
  text-align: left;
  color: #707c97;
  padding-left: 10px;
  padding-right: 10px;
}

.new-project-btn {
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-weight: 400;
  color: #f8f9fe;
  background-color: #456bf1;
  width: 150px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.projects-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  animation: example 0.2s linear;
}

.overlay-card {
  background-color: #fcfdff;
  width: 70%;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

@keyframes example {
  from {
    width: 0vw;
  }

  to {
    width: 100vw;
  }
}

.project-main-heading {
  font-family: Lato;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #606981;
}

.project-details-container {
  background: #f5f8fc;
  padding: 10px;
  margin-top: 15px;
  padding: 30px;
  padding-top: 20px;
}

.project-details-heading {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #606981;
  margin-bottom: 25px;
}

.label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8f949f;
  margin-bottom: 10px;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  outline: none;
}

.filter-txt {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  outline: none;
}

.filter-txt-desc {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  outline: none;
  height: 100px;
  width: 500px;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.filter-select:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-select:focus {
  color: #606981;
  opacity: 1;
}

.filter-txt::placeholder {
  opacity: 0.5;
}

.create-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #e2e9ff;
  color: #f5f8fc;
  backdrop-filter: blur(40.5px);
  background-color: #456bf1;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
}

.cancel-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #e2e9ff;
  color: #456bf1;
  backdrop-filter: blur(40.5px);
  background-color: #f5f8fc;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
}

.empty-view {
  width: 100%;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
}
