.sub-in-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 0px;
  cursor: pointer;
}

.sub-name-progress {
  width: 28%;
}

.sub-name {
  display: flex;
  align-items: center;
  height: 24px;
}

.avg-marks {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 700;
  line-height: 11.52px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #6276e5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18%;
  padding-right: 30px;
}

.marks-container {
  width: 14%;
  text-align: left;
}

.marks {
  font-family: "Lato";
  height: 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.45px;
  text-align: center;
  padding: 5px 20px;
  border-radius: 15px;
}

.teacher-name {
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #606981;
  display: flex;
  align-items: center;
  width: 23%;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-bar {
  background: #e2e9ff;
  box-shadow: 0px 1px 3px 0px #0000001a;
  width: 200px;
  height: 8px;
  margin-right: 10px;
  border-radius: 10px;
}

.progress {
  background: #6276e5;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.progress-per {
  font-family: Lato;
  font-size: 14.54px;
  font-weight: 700;
  line-height: 17.45px;
  letter-spacing: -0.1817745864391327px;
  text-align: left;
  align-self: flex-start;
}

.sub {
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #606981;
  width: 80% !important;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hr-line {
  border: 1px solid #eff3f9;
}

.toggle-btn {
  background-color: #eff3ff;
  padding: 5px;
  border-radius: 12px;
  height: 25px;
  align-self: flex-end;
  margin-left: 20px;
}

.attainment-table-container {
  display: flex;
  padding: 0px 20px 30px 30px;
  margin-top: 0px;
}
