@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.overlay-title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #606981;
}

.overlay-subtitle {
  color: #040c58;
  font-family: "Merriweather Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
}

.overlay-branch-text {
  color: #8f949f;
  text-align: right;
  font-family: "Merriweather Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.statements-filter {
  background-color: #f5fafe;
  border-top: 1px solid #e2e9ff;
}

.statement-title {
  color: #575757;
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.filter-select {
  border: none;
  margin-bottom: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 30px) 17px !important;
  outline: none;
  width: 100%;
  color: #040c58;
  font-family: Lato;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  padding-right: 5%;
}

.filter-option {
  font-size: 18px;
  width: 10px;
}

.po-btn {
  border-radius: 30px;
  border: 1px solid #e2e9ff;
  font-family: "Merriweather Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 10px;
  width: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.po-btn-count {
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.table-container {
  border-radius: 7px;
  border: 2px solid rgba(184, 198, 240, 0.5);
  background: #fff;
}

.po-statement {
  color: #040c58;
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-top: 6px;
  margin-left: 25px;
}

.po-statement-title {
  font-weight: 800;
}

.table {
  border-radius: 7px;
  border: 2px solid rgba(184, 198, 240, 0.5);
  background: #fff;
  border-left: 0;
  border-right: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.po-metrics-values {
  color: #040c58;
  font-family: "Lato";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  width: 50%;
}

.po-metrics {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.45;
}

.comp-header {
  width: 35%;
  font-weight: 800;
  color: #040c58;
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  padding: 10px;
  padding-left: 20px;
}

.ind-header {
  width: 60%;
  font-weight: 800;
  color: #040c58;
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  padding: 10px;
  padding-left: 25px;
}

.competency {
  color: #000;
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 165% */
  width: 35%;
  padding: 18px;
}

.indicators {
  color: #000;
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 165% */
  width: 65%;
}

.ind-li {
  padding: 0;
  margin: 0;
  padding-left: 18px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 15px;
  border-top: 2px solid rgba(184, 198, 240, 0.5);
  width: 100%;
  display: flex;
  align-items: center;
}

.ind-li:first-child {
  border: none;
}

.table-item {
  border-top: 2px solid rgba(184, 198, 240, 0.5);
}

.table-item:first-child {
  border-top: none;
}

.content {
  overflow-y: scroll;
  max-height: 500px;
}

.content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

ul::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
ul {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.green-indicators-button {
  border-radius: 7px;
  background: #27ae7a;
  box-shadow: 0px 2px 2px 1px rgba(69, 107, 241, 0.1);
  color: #fff;
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 165% */
  padding: 4px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  width: min-content;
}

.indicators-button {
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 2px 2px 1px rgba(69, 107, 241, 0.1);
  color: #000;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 165% */
  padding: 4px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  width: min-content;
}

.tick {
  width: 19px;
  height: 19px;
  margin-left: 12px;
  margin-right: 15px;
}

.justification-btn {
  color: #456bf1;
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
}

.floatarr {
  transform: rotate(180deg);
}
