.project-main-heading {
  font-family: Lato;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #606981;
}

.project-details-container {
  background: #f5f8fc;
  padding: 10px;
  margin-top: 15px;
  padding: 30px;
  padding-top: 20px;
}

.project-details-heading {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #606981;
  margin-bottom: 25px;
}

.label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8f949f;
  margin-bottom: 10px;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  outline: none;
}

.filter-txt {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  outline: none;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.filter-select:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-select:focus {
  color: #606981;
  opacity: 1;
}

.filter-txt::placeholder {
  opacity: 0.5;
}

.create-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #e2e9ff;
  color: #f5f8fc;
  backdrop-filter: blur(40.5px);
  background-color: #456bf1;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
}

.cancel-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #e2e9ff;
  color: #456bf1;
  backdrop-filter: blur(40.5px);
  background-color: #f5f8fc;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
}

.all-settings {
  font-family: Lato;
  font-size: 18px;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #3d65f4;
  display: flex;
  align-items: center;
}

.empty-view {
  width: 100%;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
}
