.art-matrix-table > tr > th {
  background-color: #eff3ff;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #040c58;
  padding: 10px 20px;
}

.art-matrix-table > tr > td {
  background-color: #fff;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding: 10px 30px;
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  text-transform: capitalize;
}
