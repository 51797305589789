.project-list-item {
  width: 49%;
  min-height: 380px;
  max-height: 380px;
  padding: 20px;
  box-shadow: 0px 1px 3px 0px #0000001a;
  border: 1px solid #eff3f9;
  border-radius: 12px;
  margin-bottom: 15px;
  position: relative;
}

.project-list-item > div > h1 {
  font-family: "Merriweather Sans";
  font-size: 18px;
  font-weight: 400;
  color: #606981;
  margin-bottom: 10px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
}

.project-list-item > p {
  font-family: "Merriweather Sans";
  font-size: 13px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-top: 15px;
  height: 90px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.project-list-item > p::-webkit-scrollbar {
  display: none;
}

.priority-box {
  width: 134.81px;
  height: 30px;
  border-radius: 7px;
  border: 1px solid;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.priority-high {
  border-color: #e35050;
  color: #e35050;
  background-color: #e3505017;
}

.priority-low {
  border-color: #27ae7a;
  color: #27ae7a;
  background-color: #27ae7a17;
}

.task-done {
  display: flex;
  align-items: center;
  color: #6276e5;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: #e2e9ff;
  border-radius: 5px;
  margin-top: 5px;
}

.progress-bar {
  background-color: #6276e5;
  height: 8px;
  border-radius: 10px;
}

.end-date {
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #606981;
}

.profile-dp-container {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-top: 15px;
}

.profile-dp-container:first-child {
  margin-left: 0;
}

.profile-dp {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: -15px;
  border: 3px solid #ffffff;
}

.profile-count {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2.5px solid #ffffff;
  background-color: #040c58;
  margin-left: -15px;
  color: #ffffff;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-edit-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border: solid #456bf1 1px;
  border-radius: 7px;
  box-shadow: 0px 7px 10px 0px #0000001a;
  padding: 10px;
  padding-left: 20px;
  background-color: #ffffff;
  width: 150px;
  height: 80px;
  position: absolute;
  right: 3%;
  top: 12%;
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  color: #040c58;
}

.course-title {
  font-family: "Lato";
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-style: normal;
}

.modal {
  background-color: #ffffff;
  position: relative;
  width: 450px;
  min-height: 200px;
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow-y: auto;
}

.criteria-btn {
  font-family: "Merriweather Sans";
  font-size: 12px;
  font-weight: 700;
  color: #456bf1;
  height: 28px;
  border-radius: 0px 0px 12.5px 12px;
  border: 1px 0px 0px 0px;
  border: 1px solid #e2e9ff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px;
  text-align: center;
}

.tier {
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 500;
  color: #606981;
}
