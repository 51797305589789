.co-statement-text {
  font-size: 16px;
  font-family: "Lato";
  font-weight: 600;
  word-spacing: 5px;
}

.filter-select-desc {
  font-size: 16px;
  font-family: "Merriweather Sans";
  font-weight: 600;
  letter-spacing: 0.25px;
  color: #3b3b3c;
  font-style: normal;
  background: #f9fbff;
  outline: none;
  margin: 0;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 10px;
  text-align: left;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.filter-select-desc::-webkit-scrollbar {
  display: none;
}

.filter-select-desc::placeholder {
  opacity: 0.5;
}

.filter-select-desc:focus {
  border-color: #456bf1;
}
