@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.add-students-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.add-students-button {
  background: #1c60ff;
  font-family: "Lato";
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #ffffff;
  width: 174px;
  height: 45px;
  border-radius: 3px 0px 0px 0px;
  box-shadow: 2px 1px 5px 0px #00000033;
  margin-top: 5px;
}

.dashboard-periodwisebar-bg {
  width: 1090px;
  margin-left: 15px;
  margin-right: 13px;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 10px;
  background: #ffffff;
  position: relative;
  top: 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;
  height: 100px;
}

.excel-view-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fcfdff;
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

.excel-sheet-container {
  margin-top: 20px;
  width: 98%;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: example 0.2s linear;
}

.overlay-card {
  background-color: #fcfdff;
  width: 70%;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
  right: 0;
  position: absolute;
}

.overlay-card-input-data {
  background-color: #fcfdff;
  position: absolute;
  width: 88%;
  right: -100%;
  max-width: 1300px;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

.overlay-title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #606981;
}

.overlay-tab {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.25px;
  color: #606981;
}

.overlay-tab-active {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  letter-spacing: 0.25px;
  color: #456bf1;
  text-decoration: underline;
}
