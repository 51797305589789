.label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8f949f;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  outline: none;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.filter-txt {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 10px;
  outline: none;
}

.filter-txt::placeholder {
  opacity: 0.5;
}

.pso-filter-txt {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 50%;
}

.pso-filter-txt::placeholder {
  opacity: 0.5;
}

.filter-select:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-select:focus {
  color: #606981;
  opacity: 1;
}

.overlay-tab {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.25px;
  color: #606981;
}

.overlay-tab-active {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  letter-spacing: 0.25px;
  color: #456bf1;
  text-decoration: underline;
}

.course-analysis-title {
  font-family: "Lato";
  font-weight: 900;
  font-size: 18px;
  color: #606981;
  letter-spacing: 0.5px;
  font-style: normal;
  margin-bottom: 20px;
}

.existing-pso-box {
  width: 100%;
  min-height: 144px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #1c60ff;
  border: none;
  backdrop-filter: blur(40.5px);
  color: #040c58;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.462px;
  letter-spacing: -0.106px;
  padding: 10px;
  margin-bottom: 14px;
}

.pso-btn {
  width: 80px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #1c60ff;
  background: #e2e9ff;
  backdrop-filter: blur(40.5px);
  color: #040c58;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.462px;
  letter-spacing: -0.106px;
  margin-right: 12px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-select {
  font-weight: 400;
  font-size: 14px;
  font-family: "Merriweather Sans";
  width: 100%;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 10px;
}
