.bg-containers {
  border: 1px solid #e2e9ff;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
}

.table-td {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
}

.table-th {
  font-family: "Merriweather Sans";
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
}
