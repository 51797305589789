.dashboard-container {
  position: relative;
  top: 105px;
  left: 20px;
  padding: 4px;
  display: flex;
  width: 1120px;
  padding-bottom: 3%;
  display: flex;
  flex-direction: column;
}

.metric-value {
  font-family: "Merriweather Sans";
  font-size: 18px;
  font-weight: 400;
  color: #6276e5;
  margin-bottom: 2px;
  width: 100%;
  display: flex;
  align-items: center;
}

.metric-value-container {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  max-width: 50%;
}

.progress-bar-container {
  width: 280px;
  height: 12px;
  background-color: #e2e9ff;
  border-radius: 5px;
  margin-top: 5px;
}

.progress-bar {
  background-color: #6276e5;
  height: 12px;
  border-radius: 10px;
}

.profile-dp-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: 8%;
  width: 300px;
}

.profile-dp-container:first-child {
  margin-left: 0;
}

.profile-dp {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-left: -15px;
  border: 3px solid #ffffff;
}

.profile-count {
  color: #121212;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
}

.search-input-container {
  box-shadow: 0px 2px 3px 2px #0000000a;
  border-width: 0px;
  border-style: solid;
  border-color: #e2e9ff;
  width: 300px;
  height: 50px;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  padding: 10px;
  padding-right: 2px;
  display: flex;
  align-items: center;
  border-radius: 12px;
}

.search-input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  margin-left: 15px;
  margin-right: 20px;
}

.dotted-plus {
  font-size: 28px;
  font-weight: 500;
  color: #c9c9c9;
  border: 2px dashed #d0d0d0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
  margin-left: 30px;
  margin-top: 5px;
}

.metrics-status-card {
  width: 1090px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin-top: 25px;
}

.metrics-status-card > li {
  width: 18.5%;
  height: 120px;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px;
}

.metrics-status-card > li > h1 {
  font-family: "Lato";
  font-size: 45px;
  font-weight: 700;
  text-align: left;
  color: #37464f;
}

.metrics-status-card > li > h1 > span {
  font-family: "Merriweather Sans";
  font-size: 18px;
  font-weight: 400;
  text-align: right;
  color: #8f949f;
  margin-left: 10px;
}

.metrics-status-card > li > h2 {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.5px;
  color: #606981;
}

.metrics-list {
  width: 1090px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style-type: none;
  margin-top: 25px;
}

.metrics-list > li {
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 0;
  border-radius: 12px;
  width: 32%;
  height: 216px;
  border: 1px solid #e2e9ff;
  box-shadow: 0px 1px 3px 0px #0000001a;
  position: relative;
  margin-right: 14px;
  margin-top: 14px;
}

.metrics-list > li > h1 {
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #040c58;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.metrics-list > li > p {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #606981;
}

.metrics-list > li > p > span {
  font-family: Merriweather Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  text-align: right;
  color: #8f949f;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  animation: example 0.2s linear;
}

.overlay-card {
  background-color: #fcfdff;
  width: 70%;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

@keyframes example {
  from {
    width: 0vw;
  }

  to {
    width: 100vw;
  }
}

.empty-view {
  width: 100%;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
}
