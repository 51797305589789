.labels {
  font-weight: 400;
  font-size: 16px;
  font-family: "Merriweather Sans";
  text-transform: capitalize;
  width: 100%;
}

.class-label {
  background: #f5f8fc;
  border: 1px solid #e2e9ff;
  width: 100%;
}

.map-button {
  width: 80px;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 900;
  color: #ffffff;
  background: #456bf1;
  border-width: 0px;
  border-radius: 18px;
  text-align: center;
}

.popup-btn {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px; /* lowercase */
  height: 34px; /* lowercase */
  border-radius: 15px;
}

.choose-btn {
  border: 1px solid #e2e9ff;
  background: #ffffff;
  font-family: Merriweather Sans;
  font-size: 14px;
  font-weight: 700;
  color: #456bf1;
  border-radius: 5px;
  border: 1px solid #e2e9ff;
  width: 134px;
  height: 41px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.dropdown {
  position: absolute;
  background: #ffffff;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000;
  left: 480px;
  border: 1px solid #456bf1;
  box-shadow: 0px 2px 7px 0px #0000001a;
  width: 326px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  max-height: 300px;
  overflow-y: auto;
}

.search-input {
  width: 100%;
  padding: 6px 8px 6px 19px;
  margin-bottom: 10px;
  background: #f3f4f8;
  border: 1px solid #d4d8e9;
  box-shadow: 0px 2px 11px 0px #0000000d inset;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  background: transparent;
  border-width: 0px;
  outline: none;
}

.dropdown-list {
  list-style: none;
  padding: 0;
}

.dropdown-list li {
  padding: 8px;
  cursor: pointer;
  font-family: Merriweather Sans;
  font-size: 16px;
  font-weight: 400;
  color: #606981;
}

.dropdown-list li:hover {
  background-color: #f1f1f1;
}

.filters {
  font-weight: 700;
  font-size: 14px;
  font-family: "Merriweather Sans";
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 92% !important;
  outline: none;
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.modal {
  background-color: #ffffff;
  position: relative;
  width: 500px;
  min-height: 300px;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
}

.modal-container {
  overflow-y: auto;
  width: 450px;
  min-height: 150px;
  max-height: 470px;
}
