@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.table {
  width: 570px;
  margin-top: 20px;
}

.overlay-title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #606981;
}

.overlay-heading {
  color: #456bf1;
  font-size: 19px;
  font-family: "Lato";
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.25px;
}

.label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8f949f;
  margin-bottom: 8px;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  padding: 10px;
  outline: none;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.buttons {
  color: #456bf1;
  font-size: 14px;
  font-family: "Merriweather Sans";
  line-height: 24.462px;
}

.matrix {
  color: #040c58;
  font-size: 16px;
  font-family: "Lato";
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.25px;
}

.labels {
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  line-height: 18px;
  text-transform: uppercase;
  padding: 10px;
  outline: none;
}

.labels::placeholder {
  color: #c6c6c6;
}

.label-dropdowns {
  color: #c6c6c6;
  font-size: 12px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  line-height: 18px;
  text-transform: capitalize;
}

.filter-select-desc {
  font-size: 16px;
  font-family: "Lato";
  font-weight: 600;
  letter-spacing: 0.25px;
  color: #3b3b3c;
  font-style: normal;
  background: #f9fbff;
  outline: none;
  margin: 0;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 10px;
  text-align: left;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-color: #456bf1;
}

.filter-select-desc::-webkit-scrollbar {
  display: none;
}

.filter-select-desc::placeholder {
  opacity: 0.5;
}

.co-statement-text {
  font-size: 16px;
  font-family: "Lato";
  font-weight: 600;
  word-spacing: 5px;
}

.filter-select-rating {
  font-size: 16px;
  font-family: "Lato";
  font-weight: 600;
  letter-spacing: 0.25px;
  color: #8f949f;
  font-style: normal;
  background: #f9fbff;
  outline: none;
  margin: 0;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 10px;
}

.star {
  background-image: url("/public/assets/star.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1c60ff;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
}
