@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.labels {
  color: #8f949f;
  font-size: 14px;
  font-family: "Merriweather Sans";
  line-height: 18px;
  margin-bottom: 10px;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  outline: none;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.filter-select:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-select:focus {
  color: #606981;
  opacity: 1;
}

.button-wrap {
  position: relative;
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 172px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #e2e9ff;
  background: #f5f8fc;
  backdrop-filter: blur(40.5px);
  color: #456bf1;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
  margin-right: 15px;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 5px;
}

.button-pop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 2px solid #95aaec;
  background: #f5f8fc;
  backdrop-filter: blur(40.5px);
  color: #456bf1;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-right: 15px;
}

.file {
  color: #8f949f;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  margin-left: 20px;
}

.modal {
  background-color: #ffffff;
  position: relative;
  width: 300px;
  min-height: 200px;
  max-height: 600px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  overflow-y: auto;
  border-radius: 4px;
}

.section-dropdown {
  height: 40px !important;
  padding-top: 0;
}

.sec-check {
  height: 18px;
  width: 20px;
  cursor: pointer;
}

.sec-check-label {
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-weight: 400;
  color: #606981;
  cursor: pointer;
}

.cross-mark {
  font-size: 8px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  rotate: -5deg;
  margin-left: 4px;
}
