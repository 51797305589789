@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.table {
  width: 100%;
  display: flex;
}

.table-scroll {
  position: relative;
  width: 300%;
  z-index: 1;
  margin: auto;
  overflow: auto;
}

.table-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.table-scroll table {
  width: 100%;
  min-width: 200%;
  margin: auto;
  border-collapse: collapse;
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}
.table-scroll th,
.table-scroll td {
  border: none;
  vertical-align: top;
  background-color: #fff;
}
.table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
/* safari and ios need the tfoot itself to be position:sticky also */
.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 4;
}

.overlay-title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #606981;
}

.overlay-heading {
  color: #456bf1;
  font-size: 19px;
  font-family: "Lato";
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.25px;
}

.label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8f949f;
  margin-bottom: 8px;
}

.co-statement-text {
  font-size: 16px;
  font-family: "Lato";
  font-weight: 600;
  word-spacing: 5px;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  overflow: hidden;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.filter-select-desc {
  font-size: 16px;
  font-family: "Merriweather Sans";
  font-weight: 600;
  letter-spacing: 0.25px;
  color: #3b3b3c;
  font-style: normal;
  background: #f9fbff;
  outline: none;
  margin: 0;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 10px;
  text-align: left;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.filter-select-desc::-webkit-scrollbar {
  display: none;
}

.filter-select-desc::placeholder {
  opacity: 0.5;
}

.filter-select-desc:focus {
  border-color: #456bf1;
}

.articulation {
  color: #fff;
  font-size: 18px;
  font-family: "Lato";
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.matrix-text {
  color: #606981;
  font-size: 19px;
  font-family: "Lato";
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.25px;
}

.matrix {
  color: #040c58;
  font-size: 16px;
  font-family: "Lato";
  font-weight: 600;
  /* line-height: 16px; */
  letter-spacing: 0.25px;
}

.labels {
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  line-height: 18px;
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  padding: 8px;
  outline: none;
}

.label-dropdowns {
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  line-height: 18px;
  text-transform: capitalize;
  padding: 10px;
  border: 1.5px solid #f1f3f5;
  outline: none;
}

.label-box {
  width: 65px;
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  line-height: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: 1.5px solid #e8e9ea;
  padding: 6.5px;
  outline: none;
  text-align: center;
  height: 34px !important;
}

.label-box-rub {
  width: 80%;
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  line-height: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  border: 1.5px solid #f1f3f5;
  padding: 10px;
  outline: none;
  /* text-align: center; */
}

.label-box::placeholder {
  opacity: 0.5;
}

.floatarr {
  /* right: -99%; */
  transform: rotate(180deg);
}

.react-select {
  font-weight: 400;
  font-size: 14px;
  font-family: "Merriweather Sans";
  width: 100%;
  padding: 3px;
}

.course-title {
  font-family: "Lato";
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-style: normal;
}

.batch {
  font-family: "Merriweather Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.num-label {
  color: #040c58;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  margin-bottom: 10px;
}

.label-container {
  margin-left: 18%;
  width: 25%;
}

.justification-btn {
  color: #456bf1;
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
}

.div-container {
  padding-top: 15px;
  border: 1px solid #e2e9ff;
  background: #fff;
  box-shadow: 0px 7px 9px 0px rgba(32, 23, 90, 0.1);
  margin-top: 0;
}

.co-input-container {
  border: none;
  border-bottom: solid 1px #456bf1;
  padding-bottom: 6px;
  font-family: Lato;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-align: left;
  color: #606981;
  display: flex;
  align-items: center;
}

.filter-select-co {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  outline-color: #456bf1;
  padding: 10px;
  overflow: hidden;
}

.filter-select-co::placeholder {
  opacity: 0.8;
}

.co-submit-btn {
  font-family: Lato;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: left;
  color: #456bf1;
  width: 113px;
  height: 44px;
  border-radius: 40px;
  border: solid 1px #e2e9ff;
  text-align: center;
}

.co-edit-btn {
  align-self: flex-end;
  display: flex;
  align-items: center;
  font-family: Lato;
  font-size: 16px;
  font-weight: 900;
  text-align: left;
  color: #456bf1;
  /* width: 3px; */
  /* height: 44px; */
  border-radius: 10px;
  border: solid 1px #e2e9ff;
  text-align: center;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
}

.action-lvl {
  cursor: pointer;
  font-size: 18px;
  font-family: Lato;
  background-color: #fff;
  font-weight: 600;
  padding: 5px;
  margin-bottom: 5px;
  width: 150px;
  padding-left: 15px;
  border-radius: 4px;
}

.action-lvl-active {
  background-color: #3d65f4;
  color: #fff;
}

.action-verbs {
  color: #7495ac;
  font-size: 18px;
  font-family: Lato;
  background-color: #f4f9ff;
  margin-right: 10px;
  padding: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  text-transform: uppercase;
  margin-bottom: 20px;
  min-width: 120px;
  text-align: center;
  cursor: pointer;
}

.action-verbs-active {
  font-size: 18px;
  font-family: Lato;
  background-color: #5276f9;
  color: #fff;
  margin-right: 10px;
  padding: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  text-transform: uppercase;
  margin-bottom: 20px;
  min-width: 120px;
  text-align: center;
  cursor: pointer;
}

.vertical-line {
  background-color: #cfdbe8;
  height: 100%;
  width: 2px;
  border: none;
}

.text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.modal {
  background-color: #ffffff;
  position: relative;
  width: 330px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
}
