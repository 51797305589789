.small-devices {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  padding: 10px;
}

.fatcat-logo-container {
  height: 89px;
}

@media screen and (min-width: 550px) {
  .small-devices {
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
  }
}
