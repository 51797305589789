@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.labels {
  color: #8f949f;
  font-size: 14px;
  font-family: "Merriweather Sans";
  line-height: 18px;
  margin-bottom: 10px;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  outline: none;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.filter-txt {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  outline: none;
}

.filter-txt::placeholder {
  opacity: 0.5;
}

.filter-txt:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-txt:focus {
  color: #606981;
  opacity: 1;
}

.fac-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 172px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #e2e9ff;
  color: #f5f8fc;
  backdrop-filter: blur(40.5px);
  background-color: #456bf1;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
}

.button-wrap {
  position: relative;
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 172px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #e2e9ff;
  background: #f5f8fc;
  backdrop-filter: blur(40.5px);
  color: #456bf1;
  font-feature-settings: "case" on;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.5px;
  text-align: center;
  margin-right: 15px;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 5px;
}

.input-bg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #333333;
  width: 300px;
  height: 40px;
  padding-right: 10px;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
}

.input {
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0;
  width: 300px;
  height: 40px;
  color: #606981;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  display: flex;
  align-items: center;
  padding-right: 8px;
  font-family: "Lato";
}
