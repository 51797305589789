.weekly-progress-card {
  border: 0.2px solid #b8c4e7;
  box-shadow: 0px 2px 2px 1px #0000000a;
  width: 100%;
  height: 384px;
  border-radius: 12px;
  border: 0.2px 0px 0px 0px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.weekly-progress-card > h1 {
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #121212;
  position: absolute;
  top: 20px;
  left: 20px;
}

.weekly-progress-card > p {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #41475e;
  margin-top: 10px;
  position: absolute;
  top: 40px;
  left: 20px;
}

.empty-view {
  width: 100%;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
}
