@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.labels {
  color: #8f949f;
  font-size: 14px;
  font-family: "Merriweather Sans";
  line-height: 18px;
  margin-bottom: 10px;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
  outline: none;
  height: 44px;
}

.input-bg {
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #333333;
  width: 300px;
  height: 44px;
  padding-right: 10px;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
}

.input {
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0;
  width: 300px;
  height: 44px;
  color: #606981;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  display: flex;
  align-items: center;
  padding-right: 8px;
  font-family: "Lato";
}
