@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.table {
  border-width: 10px;
}

.title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #040c58;
  text-transform: capitalize;
}

.subtitle {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #040c58;
}

.text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.modal {
  background-color: #ffffff;
  position: relative;
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
