.project-type-label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #000;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 10px;
  outline: none;
}

.filter-select::placeholder {
  opacity: 0.5;
}
